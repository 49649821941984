export const CONTRACT_STATES = [
	{
		Id: 961700000,
		Name: 'STATE_READY_AWAITING_SIGNATURE',
		Progress: 10,
		Active: false,
	},
	{
		Id: 961700010,
		Name: 'STATE_SIGNED',
		Progress: 15,
		Active: false,
	},
	{
		Id: 961700012,
		Name: 'STATE_VH_DRAFT',
		Progress: 20,
		Active: true,
	},
	{
		Id: 961700013,
		Name: 'STATE_VH_HANDLING',
		Progress: 25,
		Active: true,
	},
	{
		Id: 961700014,
		Name: 'STATE_VH_ACCEPTED',
		Progress: 30,
		Active: true,
	},
	{
		Id: 961700015,
		Name: 'STATE_VH_WAITING',
		Progress: 35,
		Active: true,
	},
	{
		Id: 961700016,
		Name: 'STATE_VH_HANDLING_FAILED',
		Progress: 25,
		Active: true,
	},
	{
		Id: 961700017,
		Name: 'STATE_VH_DECLINED',
		Progress: 25,
		Active: true,
	},
	{
		Id: 961700008,
		Name: 'STATE_SIGNED_AND_ACTIVATED',
		Progress: 50,
		Active: true,
	},
	{
		Id: 961700009,
		Name: 'STATE_CANCELLED',
		Progress: 0,
		Active: false,
	},
	{
		Id: 961700018,
		Name: 'STATE_VH_COMPLETE',
		Progress: 100,
		Active: false,
	},
]

export const CONTRACT_STATES_AWAINTING_SIGNATURE = 961700000
// export const CONTRACT_STATES_COMPLETE = 961700018
export const CONTRACT_TYPE_ID_DRIFT = 1
export const CONTRACT_TYPE_ID_SKOGKULTUR = 2
export const CONTRACT_TYPE_ID_INNMELDING = 3
export const CONTRACT_TYPE_ID_LEVERANSEVIRKE = 4

export const CONTRACT_TYPES = [
	{
		Id: CONTRACT_TYPE_ID_DRIFT,
		Name: 'Drift',
		Icon: 'ot-felling',
	},
	{
		Id: CONTRACT_TYPE_ID_SKOGKULTUR,
		Name: 'Skogkultur',
		Icon: 'ot-none',
	},
	{
		Id: CONTRACT_TYPE_ID_INNMELDING,
		Name: 'Innmelding',
		Icon: 'ot-felling',
	},
	{
		Id: CONTRACT_TYPE_ID_LEVERANSEVIRKE,
		Name: 'Leveransevirke Skogeier',
		Icon: 'ot-felling',
	},
]

export const ORDER_STATE_NEW = 1 // Ny, ikke vises
export const ORDER_STATE_CONFIRMATION_SENT = 2 // Bekfreftet
export const ORDER_STATE_IN_PROGRESS = 3
export const ORDER_STATE_NO_MONEY = 4
export const ORDER_STATE_COMPLETE = 100001
export const ORDER_STATE_PARTIAL = 100002
export const ORDER_STATE_INVOICED = 100003
export const ORDER_STATE_SENT_VISMA_AS_STANDARD = 200000
export const ORDER_STATE_PROVISIONED = 961700000 // Klargjort, ikke vises
export const ORDER_STATE_ASSIGNED_CONTRACTOR = 961700001 // Bekreftet, Tildelt entreprenør
export const ORDER_STATE_SMS_SENT = 961700002 // SMS sendt, ikke i bruk
export const ORDER_STATE_COMPLETED_CONTRACTOR = 961700003 // Fullført entreprenør
export const ORDER_STATE_NOT_LOGGED = 961700004
export const ORDER_STATE_CANCELLED_FOREST_OWNER = 961700005
export const ORDER_STATE_RELOCATED_AREA = 961700006
export const ORDER_STATE_REJECTED_CONTRACTOR = 961700007 // Avvist entreprenør (Bekreftet)
export const ORDER_STATE_ACCEPTED_CONTRACTOR = 961700008 // Startet
export const ORDER_STATE_AUTHORIZED_CONTRACTOR = 961700009
export const ORDER_STATE_OTHER = 961700010

// Whitelist all other statuses
export const ORDER_STATES_INACTIVE = [
	ORDER_STATE_COMPLETE,
	ORDER_STATE_PARTIAL,
	ORDER_STATE_INVOICED,
]
export const ORDER_STATES_IGNORE = [
	ORDER_STATE_CANCELLED_FOREST_OWNER,
	ORDER_STATE_NEW,
	ORDER_STATE_OTHER,
	ORDER_STATE_PROVISIONED,
	ORDER_STATE_NO_MONEY,
	ORDER_STATE_NOT_LOGGED,
]
export const ORDER_STATES_IN_QUEUE = [ORDER_STATE_NEW, ORDER_STATE_PROVISIONED]

export const ORDER_STATES = [
	{
		Id: ORDER_STATE_NEW, // 1
		Name: 'STATE_ORDERED',
		Progress: 0,
	},
	{
		Id: ORDER_STATE_NO_MONEY, // 4
		Name: 'STATE_VH_WAITING',
		Progress: 0,
	},
	{
		Id: ORDER_STATE_SENT_VISMA_AS_STANDARD, // 200000
		Name: 'STATE_COMPLETED',
		Progress: 99,
	},
	{
		Id: ORDER_STATE_PROVISIONED, // 961700000
		Name: 'STATE_ORDERED',
		Progress: 0,
	},
	{
		Id: ORDER_STATE_CONFIRMATION_SENT, // 2
		Name: 'STATE_CONFIRMED', // Ordrebekreftelse sendt
		Progress: 25,
	},
	{
		Id: ORDER_STATE_ASSIGNED_CONTRACTOR, // 961700001
		Name: 'STATE_CONFIRMED', // Ordrebekreftelse sendt
		Progress: 33,
	},
	{
		Id: ORDER_STATE_IN_PROGRESS, // 3
		Name: 'STATE_STARTED',
		Progress: 50,
	},
	{
		Id: ORDER_STATE_SMS_SENT, // 961700002
		Name: 'STATE_CONFIRMED',
		Progress: 33,
	},
	{
		Id: ORDER_STATE_COMPLETED_CONTRACTOR, // 961700003
		Name: 'STATE_COMPLETED',
		Progress: 98,
	},
	{
		Id: ORDER_STATE_REJECTED_CONTRACTOR, // 961700007
		Name: 'STATE_CONFIRMED', // Ordrebekreftelse sendt
		Progress: 0,
	},
	{
		Id: ORDER_STATE_ACCEPTED_CONTRACTOR, // 961700008
		Name: 'STATE_STARTED',
		Progress: 50,
	},
	{
		Id: ORDER_STATE_AUTHORIZED_CONTRACTOR, // 961700009
		Name: 'STATE_COMPLETED', // Fullført entreprenør
		Progress: 100,
	},
	{
		Id: ORDER_STATE_COMPLETE, // 100001
		Name: 'STATE_INVOICED',
		Progress: 100,
	},
	{
		Id: ORDER_STATE_PARTIAL, // 100002
		Name: 'STATE_INVOICED',
		Progress: 100,
	},
	{
		Id: ORDER_STATE_INVOICED, // 100003
		Name: 'STATE_INVOICED',
		Progress: 100,
	},
]

export const ORDER_TYPE_SCARIFICATION = 1 // Markberedning
export const ORDER_TYPE_TENDING = 2 // Ungskogpleie
export const ORDER_TYPE_PLANTING = 3 // Planting
export const ORDER_TYPE_SPRAYING = 4 // Sprøyting
export const ORDER_TYPE_EXCAVATION_WORK = 5 // Gravearbeider
export const ORDER_TYPE_MANAGEMENT_OPERATIONS = 6 // Forvaltning / drift
export const ORDER_TYPE_TRUNK_LIMBING = 7 // Stammekvisting
export const ORDER_TYPE_PLANT_SALES = 8 // Plantesalg
export const ORDER_TYPE_SUPPLEMENTARY_PLANTING = 9 // Suppleringsplanting
export const ORDER_TYPE_PLANTATION_CONTROL = 10 // Plantefeltkontroll
export const ORDER_TYPE_NO_PROPERTY = 11 // - Ingen eiendom -
export const ORDER_TYPE_FERTILIZATION = 12 // Gjødsling
export const ORDER_TYPE_PRECLEARANCE_BEFORE_CLEARFELL = 13 // Forhåndsrydding før sluttavvirkning
export const ORDER_TYPE_PRECLEARANCE_BEFORE_THINNING = 14 // Forhåndsrydding før tynning
export const ORDER_TYPE_SCARIFICATION_WITH_EXCAVATOR = 15 // Markberedning med Gravemaskin
export const ORDER_TYPE_FELLING = 20 // Hogst
export const ORDER_TYPE_THINNING = 30 // Tynning
export const ORDER_TYPE_TRACK_DAMAGE_REPAIR = 50 // Sporsletting

export const ORDER_TYPES = [
	{
		Id: ORDER_TYPE_SCARIFICATION,
		Name: 'SCARIFICATION', // Markberedning
		Icon: 'ot-soil-scarification',
		Postfix: 'DAA',
	},
	{
		Id: ORDER_TYPE_TENDING,
		Name: 'TENDING', // Ungskogpleie
		Icon: 'ot-tending',
		Postfix: 'DAA',
	},
	{
		Id: ORDER_TYPE_PLANTING,
		Name: 'PLANTING', // Planting
		Icon: 'ot-planting',
		Postfix: 'PCS',
	},
	{
		Id: ORDER_TYPE_SPRAYING,
		Name: 'SPRAYING', // Sprøyting
		Icon: 'ot-spraying',
		Postfix: 'DAA',
	},
	{
		Id: ORDER_TYPE_EXCAVATION_WORK,
		Name: 'EXCAVATION_WORK', // Gravearbeider
		Icon: 'ot-excavator',
		Postfix: 'M',
	},
	{
		Id: ORDER_TYPE_MANAGEMENT_OPERATIONS,
		Name: 'MANAGEMENT_OPERATIONS', // Forvaltning / drift
		Icon: 'ot-none',
	},
	{
		Id: ORDER_TYPE_TRUNK_LIMBING,
		Name: 'TRUNK_LIMBING', // Stammekvisting
		Icon: 'ot-trunk-limbing',
	},
	{
		Id: ORDER_TYPE_PLANT_SALES,
		Name: 'PLANT_SALES', // Plantesalg
		Icon: 'ot-none',
	},
	{
		Id: ORDER_TYPE_SUPPLEMENTARY_PLANTING,
		Name: 'SUPPLEMENTARY_PLANTING', // Suppleringsplanting
		Icon: 'ot-supplementary-planting',
		Postfix: 'PCS',
	},
	{
		Id: ORDER_TYPE_PLANTATION_CONTROL,
		Name: 'PLANTATION_CONTROL', // Plantefeltkontroll
		Icon: 'ot-none',
		Postfix: 'PCS',
	},
	{
		Id: ORDER_TYPE_NO_PROPERTY,
		Name: '- Ingen eiendom -', // - Ingen eiendom -
		Icon: 'ot-none',
	},
	{
		Id: ORDER_TYPE_FERTILIZATION,
		Name: 'FERTILIZATION', // Gjødsling
		Icon: 'ot-fertilizing',
		Postfix: 'DAA',
	},
	{
		Id: ORDER_TYPE_PRECLEARANCE_BEFORE_CLEARFELL,
		Name: 'PRECLEARANCE_BEFORE_CLEARFELL', // Forhåndsrydding før sluttavvirkning
		Icon: 'ot-none',
		Postfix: 'DAA',
	},
	{
		Id: ORDER_TYPE_PRECLEARANCE_BEFORE_THINNING,
		Name: 'PRECLEARANCE_BEFORE_THINNING', // Forhåndsrydding før tynning
		Icon: 'ot-none',
		Postfix: 'DAA',
	},
	{
		Id: ORDER_TYPE_SCARIFICATION_WITH_EXCAVATOR,
		Name: 'SCARIFICATION_WITH_EXCAVATOR', // Markberedning med Gravemaskin
		Icon: 'ot-soil-scarification',
		Postfix: 'DAA',
	},
	{
		Id: ORDER_TYPE_FELLING,
		Name: 'FELLING', // Hogst
		Icon: 'ot-felling',
		Postfix: 'DAA',
	},
	{
		Id: ORDER_TYPE_THINNING,
		Name: 'THINNING', // Tynning
		Icon: 'ot-thinning',
		Postfix: 'DAA',
	},
	{
		Id: ORDER_TYPE_TRACK_DAMAGE_REPAIR,
		Name: 'TRACK_DAMAGE_REPAIR', // Sporsletting
		Icon: 'ot-excavator',
	},
	{
		Id: 'FELLING_1',
		Name: 'Hogst',
		Icon: 'ot-felling',
	},
	{
		Id: 'FELLING_2',
		Name: 'Hogst',
		Icon: 'ot-felling',
	},
	{
		Id: 'FELLING_3',
		Name: 'Hogst',
		Icon: 'ot-felling',
	},
]
